<!--
 * @Description: 地区人员设置
 * @Author: ChenXueLin
 * @Date: 2021-09-09 15:24:57
 * @LastEditTime: 2022-06-27 11:16:38
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
      v-loading="loading"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="userId">
              <el-input
                v-model="searchForm.userId"
                placeholder="用户ID"
                title="用户ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="userName">
              <el-input
                v-model="searchForm.userName"
                placeholder="用户名称"
                title="用户名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="regionalAreaId">
              <e6-vr-select
                v-model="searchForm.regionalAreaId"
                :data="regionalList"
                placeholder="大区名称"
                title="大区名称"
                clearable
                :props="{
                  id: 'areaId',
                  label: 'areaName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sectionAreaId">
              <e6-vr-select
                v-model="searchForm.sectionAreaId"
                :data="areaList"
                placeholder="片区名称"
                title="片区名称"
                clearable
                :props="{
                  id: 'areaId',
                  label: 'areaName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="cityAdminAreaId">
              <e6-vr-select
                v-model="searchForm.cityAdminAreaId"
                :data="cityList"
                placeholder="城市名称"
                title="城市名称"
                clearable
                :props="{
                  id: 'areaId',
                  label: 'areaName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="streetAreaId">
              <e6-vr-select
                v-model="searchForm.streetAreaId"
                :data="streetList"
                placeholder="街道"
                title="街道"
                clearable
                :props="{
                  id: 'areaId',
                  label: 'areaName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <el-button type="primary" @click="handleDelete"
            >删除片区人员</el-button
          >
          <el-button type="primary" @click="handleUser(1)">添加人员</el-button>
          <el-button
            type="primary"
            style="margin-right:10px;"
            @click="handleUser(2)"
            >区域转移</el-button
          >
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          ref="elTable"
          @selection-change="handleSelectionChange"
          :row-key="
            row => {
              return row.id;
            }
          "
        >
          <el-table-column type="selection" width="50"> </el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->
      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 添加用户弹框 -->
    <el-dialog
      v-dialogDrag
      :title="dialogType == 1 ? '添加人员' : '区域转移'"
      :visible.sync="addUserVisible"
      width="500px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="custom-dialog"
    >
      <div class="til" v-if="dialogType == 2">
        已选择{{ selColumns.length }}个工程师，请选择要转移的区域
      </div>
      <el-form
        ref="addUserForm"
        label-width="110px"
        :model="addUserForm"
        :inline="true"
        :rules="rules"
      >
        <el-form-item prop="regionaId" label="大区名称">
          <e6-vr-select
            v-model="addUserForm.regionaId"
            :data="regionalList"
            placeholder="大区名称"
            title="大区名称"
            clearable
            :props="{
              id: 'areaId',
              label: 'areaName'
            }"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item prop="sectionAreaId" label="片区名称">
          <e6-vr-select
            v-model="addUserForm.sectionAreaId"
            :data="dialogAreaList"
            placeholder="片区名称"
            title="片区名称"
            clearable
            :props="{
              id: 'areaId',
              label: 'areaName'
            }"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item
          class="search-item--1"
          prop="cityAdminAreaId"
          label="城市/行政区"
        >
          <e6-vr-select
            v-model="addUserForm.cityAdminAreaId"
            :data="dialogCityList"
            placeholder="城市/行政区"
            title="城市/行政区"
            clearable
            :props="{
              id: 'areaId',
              label: 'areaName'
            }"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item
          prop="employeeIdList"
          label="工程师"
          v-if="dialogType == 1"
        >
          <e6-vr-select
            v-model="addUserForm.employeeIdList"
            :data="employeeList"
            placeholder="工程师"
            title="工程师"
            multiple
            clearable
            :props="{
              id: 'employeeId',
              label: 'userName'
            }"
          ></e6-vr-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  userListPage,
  // getStreetList,
  getAreaDown,
  getListIdAndNames,
  addAreaUser,
  updateUserArea,
  // createProjectEmpRel,
  deleteAreaUser
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "areaUserManage",
  components: {},
  data() {
    return {
      total: 0,
      regionalList: [], //大区下拉框
      areaList: [], //片区下拉框
      cityList: [], //城市下拉框
      employeeList: [], //工程师下拉框
      streetList: [], //街道下拉框
      selColumns: [], //勾选的数据
      loading: false,
      searchForm: {
        userId: "", //员工名称
        userName: "",
        regionalAreaId: "",
        sectionAreaId: "",
        cityAdminAreaId: "",
        streetAreaId: "",
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "用户ID",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "userName",
          display: true,
          fieldLabel: "用户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "regionalAreaName",
          display: true,
          fieldLabel: "大区",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sectionAreaName",
          display: true,
          fieldLabel: "片区",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "provinceName",
          display: true,
          fieldLabel: "省份",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "cityName",
          display: true,
          fieldLabel: "城市",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "countyName",
          display: true,
          fieldLabel: "行政区",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "streetName",
          display: true,
          fieldLabel: "街道",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "areaRoleTypeName",
          display: true,
          fieldLabel: "区域角色",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      /****添加用户*******/
      dialogAreaList: [], //片区
      dialogCityList: [], //城市
      addUserForm: {
        regionaId: "", //大区
        sectionAreaId: "", //片区
        cityAdminAreaId: "", //城市
        employeeIdList: [] //工程师
      },
      dialogType: 1, //1添加用户2区域转移
      addUserFormRules: {
        regionaId: [
          {
            required: true,
            message: "请选择大区",
            trigger: ["blur", "change"]
          }
        ],
        sectionAreaId: [
          {
            required: true,
            message: "请选择片区",
            trigger: ["blur", "change"]
          }
        ],
        employeeIdList: [
          {
            required: true,
            message: "请选择工程师",
            trigger: ["blur", "change"]
          }
        ]
      },
      moveRules: {
        regionaId: [
          {
            required: true,
            message: "请选择大区",
            trigger: ["blur", "change"]
          }
        ],
        sectionAreaId: [
          {
            required: true,
            message: "请选择片区",
            trigger: ["blur", "change"]
          }
        ],
        cityAdminAreaId: [
          {
            required: true,
            message: "请选择城市",
            trigger: ["blur", "change"]
          }
        ]
      },
      addUserVisible: false,
      dialogLoading: false,
      refTable: "elTable",
      queryListAPI: userListPage
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {
    rules() {
      let value;
      if (this.dialogType == 1) {
        value = this.addUserFormRules;
      } else {
        value = this.moveRules;
      }
      return value;
    }
  },
  watch: {
    //监控大区变化
    "searchForm.regionalAreaId": {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.getAreaDown(val, 2);
          if (oldVal && val != oldVal) {
            this.searchForm.sectionAreaId = "";
            this.searchForm.cityAdminAreaId = "";
            this.areaList = [];
            this.cityList = [];
          }
        }
        if (!val) {
          this.searchForm.sectionAreaId = "";
          this.searchForm.cityAdminAreaId = "";
          this.areaList = [];
          this.cityList = [];
        }
      }
    },
    //监控片区
    "searchForm.sectionAreaId": {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.getAreaDown(val, 3);
          if (oldVal && val != oldVal) {
            this.searchForm.cityAdminAreaId = "";
            this.searchForm.streetAreaId = "";
            this.cityList = [];
            this.streetList = [];
          }
        }
        if (!val) {
          this.searchForm.cityAdminAreaId = "";
          this.searchForm.streetAreaId = "";
          this.cityList = [];
          this.streetList = [];
        }
      }
    },
    //监控地区
    "searchForm.cityAdminAreaId": {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.getStreetList(val);
          if (oldVal && val != oldVal) {
            this.searchForm.streetAreaId = "";
            this.streetList = [];
          }
        }
        if (!val) {
          this.searchForm.streetAreaId = "";
          this.streetList = [];
        }
      }
    },
    //添加用户大区
    "addUserForm.regionaId": {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.getAreaDown(val, 4);
          if (oldVal && val != oldVal) {
            this.addUserForm.sectionAreaId = "";
            this.addUserForm.cityAdminAreaId = "";
            this.areaList = [];
            this.cityList = [];
          }
        }
        if (!val) {
          this.addUserForm.sectionAreaId = "";
          this.addUserForm.cityAdminAreaId = "";
          this.areaList = [];
          this.cityList = [];
        }
      }
    },
    //添加用户片区
    "addUserForm.sectionAreaId": {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.getAreaDown(val, 5);
          if (oldVal && val != oldVal) {
            this.addUserForm.cityAdminAreaId = "";
            this.cityList = [];
          }
        }
        if (!val) {
          this.addUserForm.cityAdminAreaId = "";
          this.cityList = [];
        }
      }
    }
  },
  created() {
    this.getAreaDown("", 1);
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //获取大区和片区列表
    async getAreaDown(id, type) {
      try {
        let res = await getAreaDown({ parentId: id });
        switch (type) {
          case 1:
            //大区下拉框
            this.regionalList = _.cloneDeep(res.data);
            break;
          case 2:
            //片区
            this.areaList = _.cloneDeep(res.data);
            break;
          case 3:
            //片区
            this.cityList = _.cloneDeep(res.data);
            break;
          case 4:
            //添加用户片区
            this.dialogAreaList = _.cloneDeep(res.data);
            break;
          case 5:
            //添加用户城市
            this.dialogCityList = _.cloneDeep(res.data);
            break;
        }
      } catch (error) {
        printError(error);
      }
      // finally {
      //   this.loading = false;
      // }
    },
    //获取街道下拉框
    async getStreetList(id) {
      try {
        let res = await getAreaDown({ parentId: id });
        this.streetList = res.data;
      } catch (error) {
        printError(error);
      }
    },
    //获取全部工程师列表
    async queryEmployee() {
      try {
        let res = await getListIdAndNames({
          id: 1
        });
        //工程师下拉框
        this.employeeList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    // 表格勾选id
    handleSelectionChange(columns) {
      this.selColumns = columns;
    },
    //添加用户
    handleUser(type) {
      if (type == 1) {
        //添加用户
        this.addUserVisible = true;
        this.dialogType = type;
        this.queryEmployee();
      }
      if (type == 2) {
        //区域转移
        if (!this.selColumns.length) {
          this.$message.warning("请先勾选工程师");
          return;
        }
        this.addUserVisible = true;
        this.dialogType = type;
      }
    },
    //删除片区人员
    handleDelete() {
      if (!this.selColumns.length) {
        this.$message.warning("请选择用户");
        return;
      }
      this.$confirm("是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteAreaUserReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //删除请求
    async deleteAreaUserReq() {
      try {
        this.dialogLoading = true;
        let res = await deleteAreaUser({
          idAndVersionNumberVOList: this.selColumns.map(item => {
            return Object.assign(
              {},
              {
                id: item.id,
                versionNumber: item.versionNumber
              }
            );
          })
        });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.selColumns = [];
          this.$refs.elTable.clearSelection();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //点击确定
    confirm() {
      this.$refs.addUserForm.validate(valid => {
        if (valid) {
          if (this.dialogType == 1) {
            this.addAreaUser();
          } else {
            this.updateUserArea();
          }
        }
      });
    },
    //添加用户请求
    async addAreaUser() {
      try {
        this.loading = true;
        let res = await addAreaUser({
          ...this.addUserForm,
          areaId: this.addUserForm.cityAdminAreaId
            ? this.addUserForm.cityAdminAreaId
            : this.addUserForm.sectionAreaId
        });
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //区域转移请求
    async updateUserArea() {
      try {
        this.loading = true;
        let res = await updateUserArea({
          ...this.addUserForm,
          areaId: this.addUserForm.cityAdminAreaId,
          idList: this.selColumns.map(item => {
            return Object.assign(
              {},
              {
                id: item.id,
                versionNumber: item.versionNumber
              }
            );
          })
        });
        if (res.code == "OK") {
          this.$message.success("转移成功");
          this.selColumns = [];
          this.$refs.elTable.clearSelection();
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.addUserForm = {
        regionaId: "", //大区
        sectionAreaId: "", //片区
        cityAdminAreaId: "", //城市
        employeeIdList: [] //工程师
      };
      this.dialogAreaList = []; //片区
      this.dialogCityList = []; //城市
      this.$refs.addUserForm.resetFields();
      this.selColumns = [];
      this.$refs.elTable.clearSelection();
      this.addUserVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .custom-dialog {
  .til {
    font-size: 12px;
    color: #606266;
    margin-top: 20px;
    margin-left: 20px;
  }
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 260px;
    }
  }
}
</style>
